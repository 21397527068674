// Angular
import { registerLocaleData } from '@angular/common';
// Transloco
import { TranslocoService } from '@jsverse/transloco';
// Service
import { AuthService } from '@auth-service';
import { CommonService } from './common.service';
// RxJS & Lodash
import { lastValueFrom, switchMap, take, tap } from 'rxjs';
import { get } from 'lodash';

// Import all desired locale files
import localeEn from '@angular/common/locales/en';
import localeSv from '@angular/common/locales/sv';
import localeNb from '@angular/common/locales/nb';
import localeDa from '@angular/common/locales/da';
import localeIs from '@angular/common/locales/is';
import localeFi from '@angular/common/locales/fi';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeDe from '@angular/common/locales/de';

// Register all locale data
registerLocaleData(localeEn);
registerLocaleData(localeDa);
registerLocaleData(localeSv);
registerLocaleData(localeNb);
registerLocaleData(localeIs);
registerLocaleData(localeFi);
registerLocaleData(localeEs);
registerLocaleData(localeFr);
registerLocaleData(localeIt);
registerLocaleData(localeDe);

// Load I18n locale before loading application
export function preloadI18nLocale(authService: AuthService, commonService: CommonService, transloco: TranslocoService) {
    return () => {
        return lastValueFrom(
            authService.getUserDetails().pipe(take(1)).pipe(
                switchMap((user) => {
                    const params = new URLSearchParams(window.location.search);
                    const queryLangParam = params.get('lang');
                    const localeId = queryLangParam === null ? commonService.getUserLocale(get(user, 'environment.language', '840')).locale : queryLangParam;
                    return transloco.load(localeId).pipe(
                        tap(() => transloco.setActiveLang(localeId))
                    );
                })
            )
        );
    };
}
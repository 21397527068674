// Angular
import { Injectable, OnDestroy, inject } from '@angular/core';
// Material
import { MatPaginatorIntl } from '@angular/material/paginator';
// Transloco
import { TranslocoService } from '@jsverse/transloco';
// RxJS
import { Subscription } from 'rxjs';

@Injectable()
export class MatPaginatorI18nService extends MatPaginatorIntl implements OnDestroy {
    private translocoService = inject(TranslocoService);


    // Subscriptions
    private subscriptions: Subscription[] = [];

    /**
     *Creates an instance of MatPaginatorI18nService.
     * @param {TranslocoService} translocoService
     * @memberof MatPaginatorI18nService
     */
    constructor() {
        super();
        // Items Per Page
        const itemsPerPageLabelSubscription = this.translocoService.selectTranslate('label_items_per_page').subscribe((value) => {
            this.itemsPerPageLabel = value;
        });
        this.subscriptions.push(itemsPerPageLabelSubscription);

        // First Page
        const firstPageLabelSubscription = this.translocoService.selectTranslate('label_first_page').subscribe((value) => {
            this.firstPageLabel = value;
        });
        this.subscriptions.push(firstPageLabelSubscription);

        // Last Page
        const lastPageLabelSubscription = this.translocoService.selectTranslate('label_last_page').subscribe((value) => {
            this.lastPageLabel = value;
        });
        this.subscriptions.push(lastPageLabelSubscription);

        // Next Page
        const nextPageLabelSubscription = this.translocoService.selectTranslate('label_next_page').subscribe((value) => {
            this.nextPageLabel = value;
        });
        this.subscriptions.push(nextPageLabelSubscription);

        // Previous Page
        const previousPageLabelSubscription = this.translocoService.selectTranslate('label_previous_page').subscribe((value) => {
            this.previousPageLabel = value;
        });
        this.subscriptions.push(previousPageLabelSubscription);
    }

    /**
     * On Destroy Lifehook
     * @memberof MatPaginatorI18nService
     */
    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }
}
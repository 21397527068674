// Angular
import { CdkCopyToClipboard } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
// Material
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
// Transloco
import { TranslocoDirective } from '@jsverse/transloco';
// Lodash
import { get } from 'lodash';
// Ngx-Highlight
import { HighlightAuto } from 'ngx-highlightjs';

@Component({
	selector: 'app-view-raw-payload-dialog',
	templateUrl: './view-raw-payload.dialog.component.html',
	styles: [`
		.card-body {
			max-height: 500px;
			overflow: scroll;
		}
	`],
	imports: [
		CommonModule,
		FormsModule,
		MatDialogModule,
		MatSnackBarModule,
		MatButtonModule,
		MatIconModule,
		MatCardModule,
		CdkCopyToClipboard,
		HighlightAuto,
		TranslocoDirective
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewRawPayloadDialogComponent implements OnInit {
	private readonly dialogRef = inject<MatDialogRef<ViewRawPayloadDialogComponent>>(MatDialogRef);
	private readonly snackBar = inject(MatSnackBar);
	private readonly data = inject(MAT_DIALOG_DATA);


	// Public properties
	historyLog: any;

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.historyLog = get(this.data, 'item', {});
	}

	/**
	 * On Copy & Close
	 */
	onCopyClose(): void {
		this.snackBar.open('Copying to clipboard was successful!', 'Close', {
			duration: 3000
		});
		this.dialogRef.close();
	}

}

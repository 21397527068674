<div class="col-xl-12">
  <div class="p-10">
    <!--begin::Form-->
    <form class="form" [formGroup]="validatePasswordForm">
      <h4 class="text-danger">
        Identify and Authenticate Yourself to the System
      </h4>
      <mat-dialog-content>
        <div class="form-group">
          <mat-form-field class="mat-form-field-fluid">
            <mat-label>Password</mat-label>
            <input matInput type="password" id="password" autocomplete="off" placeholder="Enter Password"
              formControlName="password" />
            <mat-error>
              Please enter your current password for identity verification
            </mat-error>
          </mat-form-field>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions align="end">
        <button mat-button (click)="onClose()">Cancel</button>
        <button class="btn" (click)="onConfirm()" mat-flat-button type="submit"
          [disabled]="loading" cdkFocusInitial>
          <i class="fas fa-user-shield text-white me-1"></i>
          <span>Confirm</span>
          @if (loading) {
            <span class="spinner-border spinner-border-sm mx-1"></span>
          }
        </button>
      </mat-dialog-actions>
    </form>
    <!--end::Form-->
  </div>
</div>
// Angular
import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
// Constants
import { APP_CONSTANT } from '@constants';
// Services
import { CommonService } from './common.service';

export const TokenInterceptor: HttpInterceptorFn = (request, next): Observable<any> => {
  const cs = inject(CommonService);

  // Add JWT Authorization token to each request after login
  const jwt = cs.getFromLocalStorage(APP_CONSTANT.JWT_TOKEN_KEY);
  if (jwt) {
    // Check in request params if the request is to be made with original admin token before impersonating
    const isRequestWithBeforeImpersonateToken = request.params.get('beforeImpersonteJwtToken');
    if (isRequestWithBeforeImpersonateToken === 'true') {
      const impersonateJwt = cs.getFromLocalStorage(APP_CONSTANT.BEFORE_IMPERSONATE_JWT_KEY);
      request = request.clone({ headers: request.headers.set('Authorization', `Bearer ${impersonateJwt}`) });
    } else {
      request = request.clone({ headers: request.headers.set('Authorization', `Bearer ${jwt}`) });
    }
  }

  return next(request);
};
// NGRX
import { createSelector } from '@ngrx/store';

export const selectAuthState = state => state.auth;

export const isLoggedIn = createSelector(selectAuthState, auth => Boolean(auth.loggedIn));

export const isLoggedOut = createSelector(isLoggedIn, loggedIn => Boolean(!loggedIn));

export const currentAuthToken = createSelector(selectAuthState, auth => auth.authToken);

export const isUserLoaded = createSelector(selectAuthState, auth => Boolean(auth.isUserLoaded));

export const currentUser = createSelector(selectAuthState, auth => auth.user);

export const userEnvironment = createSelector(selectAuthState, auth => auth.user?.userenvironment);

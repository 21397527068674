<!-- begin::Card -->
<div class="card">
    <!-- begin::form -->
    <form class="form" novalidate #form="ngForm">
        <!-- begin::body -->
        <div class="card-body">
            <pre><code [highlightAuto]="historyLog | json"></code></pre>
        </div>
        <!-- end::body -->

        <!-- begin::footer -->
        <div class="card-footer text-end py-3">
            <button class="me-2" type="button" mat-button mat-dialog-close cdkFocusInitial
                transloco="label_cancel">
            </button>
            <button type="submit" class="btn" mat-flat-button [cdkCopyToClipboard]="historyLog | json"
                (click)="onCopyClose()" transloco="label_copy">
            </button>
        </div>
        <!-- end::footer -->
    </form>
    <!-- end::form -->
</div>
<!-- end::Card -->
<h4 mat-dialog-title transloco="label_switch_between_companies"></h4>
<!--begin::Form-->
<form class="form" autocomplete="off" novalidate #form="ngForm" (ngSubmit)="onConfirm($event)">
  <mat-dialog-content>
    <!-- begin: body -->
    <div class="card-body py-4">
      <div class="col-12 d-flex mb-5">
        <label class="col-lg-5 col-xl-5 fw-bold">
          <span transloco="label_from"></span> :
        </label>
        <div class="col-lg-7 col-xl-7">
          {{data?.currentCompanyName}}
        </div>
      </div>
      <div class="col-12 d-flex mb-5">
        <label class="col-lg-5 col-xl-5 fw-bold">
          <span transloco="label_to"></span> :
        </label>
        <div class="col-lg-7 col-xl-7">
          {{data?.switchTo?.name}}
        </div>
      </div>
      <div class="col-12 d-flex mb-5">
        <label class="col-lg-5 col-xl-5  fw-bold">
          <span transloco="label_set_as_default"></span> :
        </label>
        <div class="col-lg-7 col-xl-7">
          <div class="form-check form-switch form-check-custom form-check-solid">
            <input class="form-check-input h-25px w-50px" type="checkbox" name="isDefault" [(ngModel)]="isDefault"
              value="true" id="flexSwitchDefault" />
            <label class="form-check-label" for="flexSwitchDefault">
              <span></span>
            </label>
          </div>
        </div>
      </div>
      <p class="text-muted my-3" transloco="paragraph_after_switching_to_the_company_page"></p>
    </div>
    <!-- end: body -->
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button type="button" class="me-2" mat-button mat-dialog-close transloco="label_cancel">
    </button>
    <button type="submit" class="btn" mat-flat-button cdkFocusInitial color="primary" [disabled]="loading">
      <span transloco="label_switch"></span>
      @if (loading) {
      <span class="spinner-border text-dark spinner-border-sm spinner align-middle ms-2"></span>
      }
    </button>
  </mat-dialog-actions>
</form>
<!--end::Form-->
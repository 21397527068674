// Angular
import { Injectable, inject } from '@angular/core';
// RxJs
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { catchError, retry } from 'rxjs/operators';
import { Observable, throwError, timer } from 'rxjs';
// Service
import { CommonService } from './common.service';
// Constants
import { environment } from '@environment';
import { APP_CONSTANT } from '@constants';
export const WS_ENDPOINT = environment.wsEndpoint;

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  readonly cs = inject(CommonService);


  // Private
  private socket$: WebSocketSubject<any>;
  private readonly RECONNECT_INTERVAL = 3000; // Reconnection delay in milliseconds
  private readonly MAX_RETRIES = 5;

  /**
   * Open web socket connection
   * @returns {Observable<any>}
   * @memberof WebSocketService
   */
  connect(): Observable<any> {
    if (!this.socket$ || this.socket$.closed) {
      this.socket$ = this.getWebSocket();
    }

    return this.socket$.pipe(
      retry({
        count: this.MAX_RETRIES,
        delay: (error, retryCount) => {
          console.warn(`WebSocket retry attempt #${retryCount}. Error:`,error);
          return timer(this.RECONNECT_INTERVAL); // Delay before retrying
        },
      }),
      catchError((error) => {
        console.error('WebSocket connection failed after retries:', error);
        return throwError(() => error); // Pass the error downstream
      })
    );
  }

  /**
   * Close web socket connection
   */
  close(): void {
    if (this.socket$) {
      this.socket$.complete(); // Gracefully complete the WebSocket connection
      this.socket$ = null;
    }
  }

  /**
   * Send Message via Websocket
   * @param {*} message
   * @memberof WebSocketService
   */
  sendMessage(message: any): void {
    if (this.socket$) {
      this.socket$.next(message);
    } else {
      console.warn('WebSocket is not connected.');
    }
  }

  /**
   * Return a custom WebSocket subject
   * @returns WebSocketSubject
   */
  private getWebSocket(): WebSocketSubject<any> {
    const jwt = this.cs.getFromLocalStorage(APP_CONSTANT.JWT_TOKEN_KEY);
    const webSocketUrl = `${WS_ENDPOINT}?token=${jwt}`;
    return webSocket({
      url: webSocketUrl
    });
  }

}

// Angular
import { Injectable, inject } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient, HttpResponse } from '@angular/common/http';
// Environment
import { environment } from '@environment';
// RxJs
import { catchError, Observable, of, tap } from 'rxjs';
// Service
import { CommonService } from './common.service';
// Constant
import { API_URL, APP_CONSTANT } from '@constants';
// Lodash
import { get } from 'lodash';

interface IRequestOptions {
  headers?: HttpHeaders;
  params?: HttpParams;
  body?: any
}

interface IHeaderOptions {
  options: IRequestOptions;
  url: string;
}

@Injectable({
  providedIn: 'root'
})
export class HttpUtilsService {
  readonly http = inject(HttpClient);
  readonly cs = inject(CommonService);


  /**
   * get standard content-type
   * @param extraHeaders: any
   * @returns HttpHeaders
   */
  getHTTPHeaders(extraHeaders?: any): HttpHeaders {
    const user = this.cs.getFromLocalStorage(APP_CONSTANT.LOGGED_USER);
    const timeZone = get(user, 'environment.time_zone', 'Europe/Stockholm');
    const userLang = get(user, 'environment.language', '840');
    const localeUTF = this.cs.getUserLocale(userLang).utf;
    let header = new HttpHeaders();
    header = header.set('content-type', 'application/json');
    header = header.set('accept', 'application/vnd.pgrst.object+json');
    header = header.set('x-environment-time-zone', timeZone);
    header = header.set('x-environment-language', userLang);
    header = header.set('x-locale-numeric', localeUTF);
    header = header.set('x-application-name', 'prime');

    // Set extra header into default headers
    if (extraHeaders) {
      for (let key in extraHeaders) {
        header = header.set(key, extraHeaders[key]);
      }
    }

    return header;
  }


  /**
   * getData method invokes http get call
   * @param urlPath:string
   * @param params: any
   * @param headerOption: any
   * @returns Observable
   */
  public getData<T>(urlPath: string, params?: any, headerOption?: any): Observable<T> {
    const { options, url } = this.getHeaderOptions(urlPath, params, headerOption);
    return this.http.get<T>(url, options);
  }

  /**
   * getDataWithHttpResponse method invokes http get call
   * You can observe the full response instead of the content only.
   * To do so, you have to pass observe: response into the options parameter of the function call.
   * @param urlPath:string
   * @param params: any
   * @param headerOption: any
   * @returns Observable
   */
  public getDataWithHttpResponse<T>(urlPath: string, params?: any, headerOption?: any): Observable<HttpResponse<T>> {
    const { options, url } = this.getHeaderOptions(urlPath, params, headerOption);
    return this.http.get<T>(url, { ...options, observe: 'response' });
  }

  /**
   * getDataWithHttpResponseType method invokes http get call with response as "text" instead of "json"
   * You can observe the full response instead of the content only.
   * To do so, you have to pass observe: response into the options parameter of the function call.
   * @template T
   * @param {string} urlPath
   * @param {*} [params]
   * @param {*} [headerOption]
   * @returns {Observable<HttpResponse<T>>}
   * @memberof HttpUtilsService
   */
  public getDataWithHttpResponseType<T>(urlPath: string, params?: any, headerOption?: any): Observable<HttpResponse<T>> {
    const { options, url } = this.getHeaderOptions(urlPath, params, headerOption);
    return this.http.get<T>(url, { ...options, observe: 'response', responseType: 'text' as 'json' });
  }

  /**
   * postData method invokes http post call
   * @param urlPath:string
   * @param body: any
   * @param params: any
   * @param headerOption: any
   * @returns Observable
   */
  public postData<T>(urlPath: string, body: any, params?: any, headerOption?: any): Observable<T> {
    const { options, url } = this.getHeaderOptions(urlPath, params, headerOption);
    return this.http.post<T>(url, body, options);
  }

  /**
   * postDataWithHttpResponse method invokes http post call
   * You can observe the full response instead of the content only.
   * To do so, you have to pass observe: response into the options parameter of the function call.
   * @param urlPath:string
   * @param body: any
   * @param params: any
   * @param headerOption: any
   * @returns Observable
   */
  public postDataWithHttpResponse<T>(urlPath: string, body: any, params?: any, headerOption?: any): Observable<HttpResponse<T>> {
    const { options, url } = this.getHeaderOptions(urlPath, params, headerOption);
    return this.http.post<T>(url, body, { ...options, observe: 'response' });
  }

  /**
   * putData method invokes http put call
   * @param urlPath:string
   * @param body: any
   * @param params: any
   * @param headerOption: any
   * @returns Observable
   */
  public putData<T>(urlPath: string, body: any, params?: any, headerOption?: any): Observable<T> {
    const { options, url } = this.getHeaderOptions(urlPath, params, headerOption);
    return this.http.put<T>(url, body, options);
  }

  /**
   * patchData invokes http patch call
   * @template T
   * @param {string} urlPath
   * @param {*} body
   * @param {*} [params]
   * @param {*} [headerOption]
   * @returns {Observable<T>}
   * @memberof HttpUtilsService
   */
  public patchData<T>(urlPath: string, body: any, params?: any, headerOption?: any): Observable<T> {
    const { options, url } = this.getHeaderOptions(urlPath, params, headerOption);
    return this.http.patch<T>(url, body, options);
  }

  /**
   * deleteData method invokes http delete call
   * @param urlPath:string
   * @param params: any
   * @param headerOption: any
   * @param body: any
   * @returns Observable
   */
  public deleteData<T>(urlPath: string, params?: any, headerOption?: any, body?: any): Observable<T> {
    const { options, url } = this.getHeaderOptions(urlPath, params, headerOption, body);
    return this.http.delete<T>(url, options);
  }

  /**
   * deleteDataWithHttpResponse method invokes http delete call
   * You can observe the full response instead of the content only.
   * To do so, you have to pass observe: response into the options parameter of the function call.
   * @param urlPath:string
   * @param params: any
   * @param headerOption: any
   * @param body: any
   * @returns Observable
   */
  public deleteDataWithHttpResponse<T>(urlPath: string, params?: any, headerOption?: any, body?: any): Observable<HttpResponse<T>> {
    const { options, url } = this.getHeaderOptions(urlPath, params, headerOption, body);
    return this.http.delete<T>(url, { ...options, observe: 'response' });
  }

  /**
   * patchDataWithHttpResponse method invokes http patch call
   * You can observe the full response instead of the content only.
   * To do so, you have to pass observe: response into the options parameter of the function call.
   * @param urlPath:string
   * @param body: any
   * @param params: any
   * @param headerOption: any
   * @returns Observable
   */
  public patchDataWithHttpResponse<T>(urlPath: string, body: any, params?: any, headerOption?: any): Observable<HttpResponse<T>> {
    const { options, url } = this.getHeaderOptions(urlPath, params, headerOption);
    return this.http.patch<T>(url, body, { ...options, observe: 'response' });
  }

  /**
   * Get Header Options and API Url Path
   * @param urlPath:string
   * @param params: any
   * @param headerOption: any
   * @returns IHeaderOptions
   */
  private getHeaderOptions(urlPath: string, params?: any, headerOption?: any, body?: any): IHeaderOptions {
    const options: IRequestOptions = {
      params,
      body,
      headers: this.getHTTPHeaders(headerOption)
    };
    const url: string = environment.apiBaseUrl + urlPath;
    return { options, url };
  }

  /**
   * Get logistic country list
   * @returns {Observable<any>}
   */
  public getLogisticCountryList(): Observable<any> {
    const LOGISTIC_COUNTRY_LIST_KEY = 'logistic-countires-v2';
    // Check IF data available in local storage if DO then return same as Observable
    const countriesListFromLS = this.cs.getFromLocalStorage(LOGISTIC_COUNTRY_LIST_KEY);
    if (countriesListFromLS) {
      return of({ body: countriesListFromLS })
    } else {
      return this.getDataWithHttpResponse(API_URL.COUNTRIES_LIST, null, { 'accept': 'application/json' })
        .pipe(
          tap((response: any) => {
            // Set country list data into local storage
            if (get(response, 'ok')) this.cs.setIntoLocalStorage(LOGISTIC_COUNTRY_LIST_KEY, get(response, 'body'))
          }),
          catchError((error: any) => of({ body: [] }))
        );
    }
  }
}

// Angular
import { Injectable } from '@angular/core';
// Models
import { IUserLocale } from '@shared/models';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    public sharedData: any = {};
   
    constructor() {
    }

    /**
     * SET SHARED OBJECT INSTANCE STOARGE
     * @param key: string
     * @param value: any
     * @returns void
     */
    setIntoSharedData(key: string, value: any): void {
        this.sharedData[key] = value;
    }

    /**
     * GET SHARED OBJECT INSTANCE FROM STOARGE
     * @param key: string
     * @returns any
     */
    getFromSharedData(key: string): any {
        return this.sharedData[key];
    }

    /**
     * SET LOCAL STOARGE SERVICES
     * @param key: string
     * @param value: any
     * @returns void
     */
    setIntoLocalStorage(key: string, value: any): void {
        let globalSharedData = localStorage.prime_globalshared_data;
        if (globalSharedData && null != globalSharedData) {
            globalSharedData = JSON.parse(globalSharedData);
            globalSharedData[key] = value;
        }
        else {
            globalSharedData = {};
            globalSharedData[key] = value;
        }
        localStorage.prime_globalshared_data = JSON.stringify(globalSharedData);
    }

    /**
     * REMOVE SHARED OBJECT INSTANCE FROM LOCAL STOARGE
     * @param key: string
     * @returns any
     */
    removeFromLocalStorage(key: string): any {
        let globalSharedData = localStorage.prime_globalshared_data;
        if (globalSharedData && null != globalSharedData) {
            globalSharedData = JSON.parse(globalSharedData);
            delete globalSharedData[key];
        }
        localStorage.prime_globalshared_data = JSON.stringify(globalSharedData);
        return globalSharedData;
    }

    /**
     * GET SHARED OBJECT INSTANCE FROM LOCAL STOARGE
     * @param key: string
     * @returns any
     */
    getFromLocalStorage(key: string): any {
        let globalSharedData = localStorage.prime_globalshared_data;
        let value;
        if (globalSharedData && null != globalSharedData) {
            globalSharedData = JSON.parse(globalSharedData);
            value = globalSharedData[key];
        }
        return value;
    }

    /**
     * SET SESSION STORAGE SERVICES
     * @param sessionkey: any
     * @param inputSessionObj: any
     * @returns void
     */
    setInSessionStorage(sessionkey: any, inputSessionObj: any): void {
        try {
            const sessionObjString = sessionStorage.getItem(sessionkey);
            let sessionObj: any = {};
            if (sessionObjString != null) {
                sessionObj = JSON.parse(sessionObjString);
            }
            // eslint-disable-next-line guard-for-in
            for (const key in inputSessionObj) {
                sessionObj[key] = inputSessionObj[key];
            }
            sessionStorage.setItem(sessionkey, JSON.stringify(sessionObj));
        } catch (ex) {
            console.log('Exception in setInSessionStorage', ex);
        }

    }

    /**
     * GET FROM SESSION STORAGE SERVICES
     * @param key: any
     * @param subKey: any
     * @returns any
     */
    getFromSessionStorage(key: string, subKey?: string | number): any {
        try {
            let sessionObj: any = sessionStorage.getItem(key);
            let sessionValue = null;
            if (null != sessionObj) {
                sessionObj = JSON.parse(sessionObj);
                if (subKey) {
                    if (null != sessionObj[subKey]) {
                        sessionValue = sessionObj[subKey];
                    }
                } else {
                    sessionValue = sessionObj;
                }
            }
            return sessionValue;

        } catch (ex) {
            console.log('Exception in getFromSessionStorage', ex);
        }

    }

    /**
     * REMOVE FROM SESSION STORAGE SERVICES
     * @param key: any
     * @param subKey: any
     * @returns void
     */
    removeFromSessionStorage(key: string, subKey?: string | number): void {
        const sessionObj = sessionStorage.getItem(key);
        try {
            if (sessionObj !== null) {
                if (subKey) {
                    const sessionObject = JSON.parse(sessionObj);
                    if (sessionObject.hasOwnProperty(subKey)) {
                        delete sessionObject[subKey];
                        sessionStorage.setItem(key, JSON.stringify(sessionObject));
                    }
                } else {
                    sessionStorage.removeItem(key);
                }
            }
        } catch (ex) {
            console.log('Exception in removeFromSessionStorage', ex);
        }
    }

    /**
     * CLEAR ALL STOARGE , SESSION SERVICES
     * @returns void
     */
    clearAll(): void {
        localStorage.clear();
        sessionStorage.clear();
    }

    /**
     * Returns user locale
     * @param langId: string
     * @returns IUserLocale
     */
    getUserLocale(langId: string): IUserLocale {
        switch (langId) {
            case '752': // Swedish
                return { locale: 'sv-se', utf: 'sv_SE.UTF8', };
            case '246': // Finnish
                return { locale: 'fi-fi', utf: 'fi_FI.UTF8' };
            case '208': // Danish
                return { locale: 'da-dk', utf: 'da_DK.UTF8' };
            case '276': // German
                return { locale: 'de-de', utf: 'de_DE.UTF8' };
            case '578': // Norwegian
                return { locale: 'nb-no', utf: 'no_NO.UTF8' };
            case '724': // Spanish
                return { locale: 'es-es', utf: 'es_ES.UTF8' };
            case '352': // Icelandic
                return { locale: 'is-is', utf: 'is_IS.UTF8' };
            case '250': // French
                return { locale: 'fr-fr', utf: 'fr_FR.UTF8' };
            case '380': // Italian
                return { locale: 'it-it', utf: 'it_IT.UTF8' };
            default: // English
                return { locale: 'en-se', utf: 'en_US.UTF8' };
        }
    }

}

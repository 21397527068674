// Angular
import { inject } from '@angular/core';
import { CanActivateFn, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
// Store
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../core/reducers/index';
import { isLoggedIn } from '../_selectors/auth.selectors';
// RxJS
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
// Utils
import { GlobalErrorHandler } from '@core/utils';

/**
 * Functional LoggedInAuthGuard
 */
export const loggedInAuthGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
): Observable<boolean> => {

    // ✅ Inject dependencies
    const store = inject(Store<AppState>);
    const router = inject(Router);
    const globalErrorHandler = inject(GlobalErrorHandler);

    return store.pipe(
        select(isLoggedIn),
        map((isLogged) => {
            if (isLogged) router.navigate(['/dashboard']); // ✅ Redirect logged-in users
            return !isLogged; // ✅ Allow only non-logged-in users
        }),
        catchError((error) => globalErrorHandler.handleError('logged-in-auth-guard', error))
    );
};

// Angular
import { Component, inject } from '@angular/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/reducers';
import { Logout } from 'src/app/core/auth';
// Material
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
    selector: 'app-logout-confirmation',
    templateUrl: './logout-confirmation.component.html',
    imports: [MatDialogModule, MatButtonModule, TranslocoDirective]
})
export class LogoutConfirmationComponent {
  private readonly store = inject<Store<AppState>>(Store);
  private readonly dialogRef = inject<MatDialogRef<LogoutConfirmationComponent>>(MatDialogRef);
  private readonly data = inject(MAT_DIALOG_DATA);


  onLogout() {
    this.store.dispatch(new Logout());
    this.dialogRef.close();
  }
}

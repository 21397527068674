export const environment = {
  production: true,
  isSentryLoggingEnable: true,
  isWebSocketEnabled: true,
  apiBaseUrl: '',
  sentryDsn: 'https://56063d89846d4d4f8a1c2f68aa1ef025@o472375.ingest.us.sentry.io/4505239034068992',
  wsEndpoint: 'wss://talk.bildelsbasen.se/ws',
  googleAnalytics: {
    isEnabled: true,
    trackId: 'G-2ZH8LNKMK8'
  }
};

// Angular
import { Injectable, inject } from '@angular/core';
// Material
import { MatDialog } from '@angular/material/dialog';
import { LogoutConfirmationComponent, SwitchCompanyComponent } from 'src/app/modules/partials/dialog';
// Components

@Injectable({
  providedIn: 'root'
})
export class LayoutUtilsService {
  private readonly dialog = inject(MatDialog);


  /**
   * Show logout confirmation dialog
   * @returns Reference to the newly-opened dialog.
   */
  showLogoutConfirmation() {
    return this.dialog.open(LogoutConfirmationComponent, {
      panelClass: 'w-500px'
    });
  }

  /**
   * Show switch company access confirmation dialog
   * @param info: any
   * @returns Reference to the newly-opened dialog.
   */
  showSwitchCompanyConfirmation(info: any) {
    return this.dialog.open(SwitchCompanyComponent, { data: info, panelClass: 'w-500px' });
  }
}

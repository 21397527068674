// Angular
import { ErrorHandler, Injectable, Injector, inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
// RxJs
import { Observable, of } from 'rxjs';
// Loadsh
import { toString } from 'lodash';
// Sentry
import * as Sentry from '@sentry/angular';
// Environment
import { environment } from '@environment';
// Store
import { Store } from '@ngrx/store';
import { Logout } from 'src/app/core/auth';

@Injectable({
    providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {
    readonly injector = inject(Injector);


    /**
     * handleError : Handle Http operation that failed . Let the app continue.
     * @param operation : name of the operation that failed
     * @param result : optional value to return as the observable result
     * @param isApiError: boolean
     */
    public handleError<T>(operation?: any, error?: any, isApiError: boolean = false): Observable<any> {

        // if no error then return emptry Observable<[]>
        if (!error && !operation) { return of([]); }

        // Handle JWT Expiry or Authorization
        if (error instanceof HttpErrorResponse && error.status === 401) {
            const store = this.injector.get(Store);
            alert('Your session has expired. Please relogin');
            store.dispatch(new Logout());
            return of(false);
        }

        // Construct Error Msg for Sentry and Console Logging
        const errorMsg: string = this.constructErrorMsg(operation, error, isApiError);

        if (environment.isSentryLoggingEnable) {
            // Send the API error to sentry logging infrastructure
            Sentry.captureMessage(errorMsg);
        } else {
            console.error(errorMsg);
        }

        // Let the app keep running by returning an empty result.
        return of(error);
    }

    /**
     * constructErrorMsg : create custom string error message
     * @param operation : name of the operation that failed
     * @param error: any
     * @param isApiError: {boolean}
     * @returns string
     */
    private constructErrorMsg(operation: any, error: any, isApiError: boolean): string {
        const startMsg: string = isApiError ? 'API ERROR' : 'JS ERROR';
        let errorMsg = `${startMsg} | ${operation}`;

        if (typeof error === 'string') {
            errorMsg += ` | ${error}`;
        }
        if (error && error.stack) {
            errorMsg += ` | ${toString(error?.stack)}`;
        }
        if (error instanceof HttpErrorResponse) {
            errorMsg += ` | ${error?.url} | ${error?.message} | ${error?.error?.message} | ${error?.error?.details}`;
        }
        if (error instanceof ErrorEvent) {
            errorMsg += ` | ${error?.error?.message}`;
        }

        // Override message incase of application error
        if (operation && operation.type === 'error') {
            errorMsg = `JS | APPLICATION ERROR`;
        }

        return errorMsg;
    }
}

// Angular
import { Injectable, OnDestroy, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// RxJS
import { filter, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { defer, of, Subscription } from 'rxjs';
// NGRX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
// Auth actions
import {
  AuthActionTypes,
  Login,
  Logout,
  UserLoaded,
  UserRequested,
} from '../_actions/auth.actions';
import { AuthService } from '@auth-service';
import { AppState } from '../../reducers';
import { isUserLoaded } from '../_selectors/auth.selectors';
import { SentryService, CommonService, WebSocketService } from '@core/utils';
import { ISentryUserIdentity } from '@shared/models';
// Lodash
import { get } from 'lodash';
// Constants
import { APP_CONSTANT } from '@constants';
// Transloco
import { TranslocoService } from '@jsverse/transloco';

@Injectable()
export class AuthEffects implements OnDestroy {
  private actions$ = inject(Actions);
  private router = inject(Router);
  private auth = inject(AuthService);
  private cs = inject(CommonService);
  readonly sentryService = inject(SentryService);
  private store = inject<Store<AppState>>(Store);
  private readonly translocoService = inject(TranslocoService);
  private readonly wsService = inject(WebSocketService);


  login$ = createEffect(() => this.actions$.pipe(
    ofType<Login>(AuthActionTypes.Login),
    tap((action: any) => {
      this.cs.setIntoLocalStorage(APP_CONSTANT.JWT_TOKEN_KEY, action.payload.authToken);
      this.cs.setIntoLocalStorage(APP_CONSTANT.LOGGED_USER, action.payload.user);
      const params = new URLSearchParams(window.location.search);
      const queryLangParam = params.get('lang');
      const localeId = null === queryLangParam ? this.cs.getUserLocale(get(action.payload, 'environment.language', '840')).locale : queryLangParam;
      this.cs.setIntoLocalStorage(APP_CONSTANT.USER_LOCALE_KEY, localeId);
      this.translocoService.setActiveLang(localeId);
      this.store.dispatch(new UserRequested());
    })
  ), { dispatch: false });


  logout$ = createEffect(() => this.actions$.pipe(
    ofType<Logout>(AuthActionTypes.Logout),
    tap(() => {
      this.cs.clearAll();
      this.auth.flushRoleAndPermissions();
      this.sentryService.resetAll();
      this.wsService.close();
      this.router.navigate(['/auth/login'], {
        queryParams: { returnUrl: this.returnUrl },
      });
    })
  ), { dispatch: false });


  loadUser$ = createEffect(() => this.actions$.pipe(
    ofType<UserRequested>(AuthActionTypes.UserRequested),
    withLatestFrom(this.store.pipe(select(isUserLoaded))),
    filter(([action, checkUserLoaded]) => !checkUserLoaded),
    mergeMap(([action, checkUserLoaded]) => this.auth.getUserDetails()),
    tap((result) => {
      if (result) {
        // Save Sentry User
        const sentryUserIdentity: ISentryUserIdentity = {
          id: get(result, 'user.id'),
          username: get(result, 'user.name'),
          email: get(result, 'user.email'),
          companyname: get(result, 'company.name', '')
        };
        this.sentryService.setUser(sentryUserIdentity);
        this.store.dispatch(new UserLoaded({ user: result }));
      } else {
        this.store.dispatch(new Logout());
      }
    })
  ), { dispatch: false });


  init$ = createEffect(() => defer(() => {
    const authToken = this.cs.getFromLocalStorage(APP_CONSTANT.JWT_TOKEN_KEY);
    const user = this.cs.getFromLocalStorage(APP_CONSTANT.LOGGED_USER);
    let observableResult = of({ type: 'NO_ACTION' });
    if (authToken) {
      observableResult = of(new Login({ user, authToken }));
    }
    return observableResult;
  }));

  // Private
  private returnUrl: string | null;
  private unsubscribe: Subscription[] = [];

  /**
   * Creates an instance of AuthEffects.
   * @param {Actions} actions$
   * @param {Router} router
   * @param {AuthService} auth
   * @param {CommonService} cs
   * @param {SentryService} sentryService
   * @param {Store<AppState>} store
   * @param {TranslocoService} translocoService
   * @param {WebSocketService} wsService
   * @memberof AuthEffects
   */
  constructor() {
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.returnUrl = event.urlAfterRedirects === APP_CONSTANT.ERROR_PATH ? null : event.url;
      }
    });
    this.unsubscribe.push(routerSubscription);
  }
  
  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

}
